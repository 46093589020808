export default {
  breakpoints: ["768px", "992px", "1200px"],
  space: [0, 8, 16, 24, 32, 48, 64, 96, 120],
  initialColorModeName: 'light',
  colors: {
    text: "#0a0a0a",
    background: "#fff",
    black: "#000",
    white: "#fff",
    primary50: "#a143cc",
    primary20: "#E3C7F0",
    primary10: "#f5e1ff",
    secondary50: "#51ACEE",
    secondary10: "#DCEEFC",
    grayXDark: "#222",
    grayDark: "#444",
    grayMedium: "#999",
    grayLight: "#ddd",
    grayXLight: "#f2f2f2",
    gold: "#D4AF37",
    fighter: "#f04923",
    invoker: "#0064b1",
    ranger: "#00a1ba",
    naturalist: "#5e9f45",
    doctor: "#dc2028",
    spy: "#4d3996",
    magician: "#ed1163",
    wizard: "#e2c122",
  },
  fonts: {
    serif: "'Ovo', serif",
    sans: "'Alegreya Sans SC', sans-serif",
    display: "'Alegreya', serif",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    xs: 16,
    sm: 22,
    md: 28,
    lg: 40,
    xl: 48,
    xxl: 72,
    xxxl: 86
  },
  lineHeights: {
    normal: 1.5,
    medium: 1.1,
    tight: 0.8,
  },
  letterSpacings: {
    normal: "0",
    medium: "0.05em",
    wide: "0.1em",
  },
  grids: {
    base: {
      maxWidth: "1600px",
      mx: "auto",
      px: [2, 4, 6],
      gridGap: 3,
      overflowX: "hidden"
    },
    textWrap: {
      maxWidth: "1600px",
      mx: "auto",
      px: [2, 4, 6],
      gridGap: 3,
      gridTemplateColumns: ["1fr", "3fr 1fr", "2fr 1fr"],
      overflowX: "hidden"
    }
  },
  text: {
    display: {
      fontFamily: "display",
      fontWeight: "normal",
      lineHeight: "medium",
      fontSize: ["xl", "xxl"],
      marginBottom: 3,
    },
    title01: {
      fontFamily: "serif",
      fontWeight: "normal",
      lineHeight: "medium",
      fontSize: ["lg", "xl"],
      mb: 2,
    },
    title02: {
      fontFamily: "sans",
      fontWeight: "bold",
      lineHeight: "medium",
      textTransform: "lowercase",
      fontSize: "lg",
      mb: 1,
    },
    title03: {
      fontFamily: "sans",
      fontWeight: "bold",
      lineHeight: "medium",
      textTransform: "lowercase",
      fontSize: "md",
      mb: 1,
    },
    title04: {
      fontFamily: "sans",
      fontWeight: "bold",
      lineHeight: "medium",
      textTransform: "lowercase",
      fontSize: "sm",
      mb: 1,
    },
    body: {
      fontFamily: "serif",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "sm",
      mb: 1,
    },
    bodyLarge: {
      fontFamily: "serif",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "md",
      mb: 3,
    },
    link: {
      display: "inline",
      textDecoration: "none",
      fontWeight: "bold",
      backgroundImage: theme => `linear-gradient(to top, ${theme.colors.primary10} 0%, ${theme.colors.primary10} 50%, transparent 50%, transparent 100%)`,
      // transition: "background 300ms",
      cursor: "pointer",
      "&:hover": {
        bg: "primary10",
      },
      "&:focus": {
        outline: "none",
        bg: "primary10",
      },
    },
    label: {
      fontFamily: "sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "xs",
      letterSpacing: "wide",
      textTransform: "lowercase",
      marginBottom: 1,
    },
    button: {
      fontFamily: "sans",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "sm",
      letterSpacing: "medium",
      textTransform: "lowercase",
    }
  },
  forms: {
    label: {
      variant: "text.label"
    },
    input: {
      variant: "text.body",
      border: "1px solid",
      borderColor: "grayMedium",
      borderRadius: 0,
      m: 0,
      "&:focus": {
        outline: "none",
        borderColor: "text"
      }
    },
    textarea: {
      variant: "text.body",
      border: "1px solid",
      borderColor: "grayMedium",
      borderRadius: 0,
      "&:focus": {
        outline: "none",
        borderColor: "text"
      }
    }
  },
  buttons: {
    primary: {
      bg: "primary10",
      color: "text",
      borderRadius: 0,
      border: "1px solid",
      borderColor: "text",
      py: 2,
      px: 4,
      variant: "text.button",
      boxShadow: theme => `6px 6px 0 ${theme.colors.text}`,
      transform: "translate(0, 0)",
      // transition: "all 300ms",
      cursor: "pointer",
      "&:hover": {
        transform: "translate(2px, 2px)",
        boxShadow: theme => `4px 4px 0 ${theme.colors.text}`,
      },
      "&:active": {
        transform: "translate(4px, 4px)",
        boxShadow: theme => `2px 2px 0 ${theme.colors.text}`,
      },
      "&:focus": {
        outline: "none",
        transform: "translate(4px, 4px)",
        boxShadow: theme => `2px 2px 0 ${theme.colors.text}`,
      },
    },
    secondary: {
      bg: "transparent",
      color: "text",
      borderRadius: 0,
      py: 2,
      px: 4,
      border: "1px solid",
      borderColor: "text",
      variant: "text.button",
      transform: "translate(0, 0)",
      cursor: "pointer",
      "&:hover": {
        bg: "grayLight"
      },
      "&:active": {
        transform: "translate(0, 1px)",
      },
      "&:focus": {
        outline: "none",
        bg: "grayLight"
      },
    },
    tertiary: {
      bg: "background",
      color: "text",
      borderRadius: 0,
      py: 0,
      px: 1,
      border: "none",
      variant: "text.button",
      transform: "translate(0, 0)",
      cursor: "pointer",
      "&:hover": {
        bg: "primary10"
      },
      "&:active": {
        transform: "translate(0, 1px)"
      },
      "&:focus": {
        outline: "none",
        bg: "primary10"
      },
    },
    primaryDisabled: {
      bg: "grayMedium",
      color: "background",
      borderRadius: 0,
      py: 2,
      px: 4,
      variant: "text.button",
      transform: "translate(0, 0)",
      transition: "background 300ms",
      cursor: "not-allowed",
      "&:hover": {
        bg: "grayMedium",
      },
      "&:active": {
        transform: "translate(0, 0)",
      },
      "&:focus": {
        outline: "none"
      },
    },
  },
  styles: {
    root: {
      bg: "background",
      fontFamily: "serif",
      fontWeight: "normal",
      fontSize: "sm",
      color: "text"
    },
    h1: {
      variant: "text.title01",
    },
    h2: {
      variant: "text.title02",
      mt: 5,
    },
    h3: {
      variant: "text.title03",
      mt: 5,
    },
    h4: {
      variant: "text.label",
    },
    p: {
      variant: "text.body",
    },
    strong: {
      variant: "text.label",
      display: "inline",
    },
    em: {
      variant: "text.body",
      fontStyle: "italic",
    },
    li: {
      variant: "text.body",
      listStyleType: "square",
    },
    blockquote: {
      py: 1,
      pl: 4,
      my: 5,
      mx: 0,
      borderLeft: "8px solid",
      "p": {
        fontFamily: "display",
        fontWeight: "normal",
        lineHeight: "medium",
        fontSize: ["md", "lg"],
        m: 0,
      }
    },
    a: {
      variant: "text.link"
    },
    hr: {
      color: "text",
      borderColor: "text",
      borderTop: "none",
      borderBottom: "1px solid",
      width: "100%",
      mx: "auto",
      my: [6, 8],
    }
  }
}