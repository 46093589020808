// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-adventures-how-to-js": () => import("./../../../src/pages/adventures/how-to.js" /* webpackChunkName: "component---src-pages-adventures-how-to-js" */),
  "component---src-pages-adventures-index-js": () => import("./../../../src/pages/adventures/index.js" /* webpackChunkName: "component---src-pages-adventures-index-js" */),
  "component---src-pages-adventures-mischief-mountain-conclusion-js": () => import("./../../../src/pages/adventures/mischief-mountain/conclusion.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-conclusion-js" */),
  "component---src-pages-adventures-mischief-mountain-index-js": () => import("./../../../src/pages/adventures/mischief-mountain/index.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-index-js" */),
  "component---src-pages-adventures-mischief-mountain-intro-js": () => import("./../../../src/pages/adventures/mischief-mountain/intro.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-intro-js" */),
  "component---src-pages-adventures-mischief-mountain-part-01-js": () => import("./../../../src/pages/adventures/mischief-mountain/part01.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-01-js" */),
  "component---src-pages-adventures-mischief-mountain-part-02-js": () => import("./../../../src/pages/adventures/mischief-mountain/part02.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-02-js" */),
  "component---src-pages-adventures-mischief-mountain-part-03-js": () => import("./../../../src/pages/adventures/mischief-mountain/part03.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-03-js" */),
  "component---src-pages-adventures-mischief-mountain-part-04-js": () => import("./../../../src/pages/adventures/mischief-mountain/part04.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-04-js" */),
  "component---src-pages-adventures-mischief-mountain-part-05-js": () => import("./../../../src/pages/adventures/mischief-mountain/part05.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-05-js" */),
  "component---src-pages-adventures-mischief-mountain-part-06-js": () => import("./../../../src/pages/adventures/mischief-mountain/part06.js" /* webpackChunkName: "component---src-pages-adventures-mischief-mountain-part-06-js" */),
  "component---src-pages-ap-js": () => import("./../../../src/pages/ap.js" /* webpackChunkName: "component---src-pages-ap-js" */),
  "component---src-pages-brell-js": () => import("./../../../src/pages/brell.js" /* webpackChunkName: "component---src-pages-brell-js" */),
  "component---src-pages-errata-js": () => import("./../../../src/pages/errata.js" /* webpackChunkName: "component---src-pages-errata-js" */),
  "component---src-pages-ethics-js": () => import("./../../../src/pages/ethics.js" /* webpackChunkName: "component---src-pages-ethics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-news-archive-js": () => import("./../../../src/pages/news/NewsArchive.js" /* webpackChunkName: "component---src-pages-news-news-archive-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-roles-doctor-js": () => import("./../../../src/pages/roles/doctor.js" /* webpackChunkName: "component---src-pages-roles-doctor-js" */),
  "component---src-pages-roles-fighter-js": () => import("./../../../src/pages/roles/fighter.js" /* webpackChunkName: "component---src-pages-roles-fighter-js" */),
  "component---src-pages-roles-index-js": () => import("./../../../src/pages/roles/index.js" /* webpackChunkName: "component---src-pages-roles-index-js" */),
  "component---src-pages-roles-invoker-js": () => import("./../../../src/pages/roles/invoker.js" /* webpackChunkName: "component---src-pages-roles-invoker-js" */),
  "component---src-pages-roles-magician-js": () => import("./../../../src/pages/roles/magician.js" /* webpackChunkName: "component---src-pages-roles-magician-js" */),
  "component---src-pages-roles-naturalist-js": () => import("./../../../src/pages/roles/naturalist.js" /* webpackChunkName: "component---src-pages-roles-naturalist-js" */),
  "component---src-pages-roles-ranger-js": () => import("./../../../src/pages/roles/ranger.js" /* webpackChunkName: "component---src-pages-roles-ranger-js" */),
  "component---src-pages-roles-spy-js": () => import("./../../../src/pages/roles/spy.js" /* webpackChunkName: "component---src-pages-roles-spy-js" */),
  "component---src-pages-roles-wizard-js": () => import("./../../../src/pages/roles/wizard.js" /* webpackChunkName: "component---src-pages-roles-wizard-js" */),
  "component---src-pages-rules-index-js": () => import("./../../../src/pages/rules/index.js" /* webpackChunkName: "component---src-pages-rules-index-js" */),
  "component---src-pages-shows-js": () => import("./../../../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */),
  "component---src-pages-social-guidelines-js": () => import("./../../../src/pages/social-guidelines.js" /* webpackChunkName: "component---src-pages-social-guidelines-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-store-character-catalog-digital-edition-js": () => import("./../../../src/pages/store/character-catalog-digital-edition.js" /* webpackChunkName: "component---src-pages-store-character-catalog-digital-edition-js" */),
  "component---src-pages-store-digital-edition-js": () => import("./../../../src/pages/store/digital-edition.js" /* webpackChunkName: "component---src-pages-store-digital-edition-js" */),
  "component---src-pages-store-index-js": () => import("./../../../src/pages/store/index.js" /* webpackChunkName: "component---src-pages-store-index-js" */),
  "component---src-pages-store-treasure-catalog-digital-edition-js": () => import("./../../../src/pages/store/treasure-catalog-digital-edition.js" /* webpackChunkName: "component---src-pages-store-treasure-catalog-digital-edition-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-retailers-mdx": () => import("./../../../src/pages/thanks-retailers.mdx" /* webpackChunkName: "component---src-pages-thanks-retailers-mdx" */),
  "component---src-pages-thanks-support-mdx": () => import("./../../../src/pages/thanks-support.mdx" /* webpackChunkName: "component---src-pages-thanks-support-mdx" */),
  "component---src-pages-thanks-upgrade-mdx": () => import("./../../../src/pages/thanks-upgrade.mdx" /* webpackChunkName: "component---src-pages-thanks-upgrade-mdx" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */)
}

